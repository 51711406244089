import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import { APPLICATION_SECTION } from "src/constants/applications.constants";
import { DirectApplyCreators } from "src/redux/actions";
import CompleteAutomation from "./complete-automation";
import FillFormCTA from "./fill-tagging-form-cta";
import ShowLastStatus from "../show-last-status";

const FillForm = ({
  t,
  taggingQuickSubmit,
  requestMarkTaggingComplete,
  application,
}) => {
  const {
    tagging_complete,
    tagging_error_message,
    tagging_job_status,
    tagging_completed_at,
    jobs_running,
    tagging_success_message,
  } = taggingQuickSubmit;

  const handleCheckboxChange = e => {
    const params = {
      section: APPLICATION_SECTION.TAGGING,
      complete: !tagging_complete,
    };
    requestMarkTaggingComplete(application?.id, params);
  };

  return (
    <div
      style={{ width: "25%" }}
      className=" flex flex-col border rounded bg-white mx-2 relative"
    >
      <div className="border-b text-base font-bold p-4 text-center truncate">
        {`${t("direct_apply_apps.quick_submit.fill_tagging_form.title")} - ${application?.portal}`}
      </div>
      <div className="flex flex-col items-center p-4">
        {tagging_job_status === "Partially Completed" &&
        !!tagging_error_message &&
        !tagging_complete &&
        !jobs_running ? (
          <div className="mb-4">
            <div className="flex">
              <div className="w-6 flex">
                <Checkbox
                  onChange={handleCheckboxChange}
                  defaultChecked={tagging_complete}
                  className="gray-900"
                />
              </div>
              <div className="flex-1 pl-1 flex">
                <span className="font-body font-normal text-base text-gray-750 leading-6 whitespace-pre-wrap text-left">
                  I have verified details in this section
                </span>
              </div>
            </div>
          </div>
        ) : (
          <CompleteAutomation />
        )}

        <FillFormCTA />

        {tagging_job_status === "Partially Completed" &&
          !!tagging_error_message &&
          !tagging_complete &&
          !jobs_running && (
            <span className="text-base text-red-800 block mb-4 text-center">
              {tagging_error_message}
            </span>
          )}

        {tagging_success_message &&
          (tagging_job_status === "Partially Completed" ||
            tagging_job_status === "Completed") && (
            <span className="text-sm text-gray-750 block mb-4 text-center">
              {tagging_success_message}
            </span>
          )}
        <ShowLastStatus time={tagging_completed_at} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    taggingQuickSubmit: state.directApply.taggingQuickSubmit,
    application: state.directApply.selected,
  };
}

const mapDispatchToProps = {
  requestMarkTaggingComplete:
    DirectApplyCreators.requestMarkTaggingComplete,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FillForm),
);

import ConnectionsConfigurationRequestsScreen from "./connections-configuration-requests";
import { connect } from "react-redux";
import { PartnersCreators } from "src/redux/actions";

function mapStateToProps(state) {
  return {
    connectionsConfiguration: state.partners.connectionsConfigurationRequests.data,
    total: state.partners.connectionsConfigurationRequests.total,
    matched: state.partners.connectionsConfigurationRequests.matched,
    isNextPageLoading: state.partners.connectionsConfigurationRequests.loading,
  };
}

const mapDispatchToProps = {
  getConnectionsConfiguration: PartnersCreators.getConnectionsConfigurationRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectionsConfigurationRequestsScreen);

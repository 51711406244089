import React, { useEffect, useState, useRef } from "react";
import "react-virtualized/styles.css";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isEqual, debounce } from "lodash";
import NoResultsFound from "src/views/direct-apply/applications/no-results-found";
import { Format } from "src/helpers";
import { Table, Filter } from "../../../components";

const sortableColumns = {
  university_name: "partners.name",
  requested_on: "updated_at",
};

const connectionsConfigurationTableColumns = onClickHeader => {
  return [
    {
      dataKey: "university_name",
      width: 200,
      type: "Default",
      disableSort: true,
      tooltip: true,
      valueSelector: rowData => ({
        value: rowData.university_name,
      }),
      header: {
        title: "University Name",
        onClick: onClickHeader,
      },
    },

    {
      dataKey: "explore_id",
      width: 100,
      type: "Default",
      disableSort: true,
      tooltip: true,
      valueSelector: rowData => ({
        value: rowData.explore_id,
      }),
      header: {
        title: "Explore id",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "paid_user",
      width: 100,
      type: "Default",
      disableSort: true,
      tooltip: true,
      valueSelector: rowData => ({
        value: rowData.paid_user ? "Yes" : "No",
      }),
      header: {
        title: "Paid User",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "user_name",
      width: 200,
      tooltip: true,
      type: "Default",
      disableSort: true,
      valueSelector: rowData => ({
        value: rowData.user_name,
      }),
      header: {
        title: "User Name",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "user_email",
      width: 200,
      tooltip: true,
      type: "Default",
      disableSort: true,
      valueSelector: rowData => ({
        value: rowData.user_email,
      }),
      header: {
        title: "User Email",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "request_type",
      width: 100,
      type: "Default",
      disableSort: true,
      valueSelector: rowData => ({
        value: rowData.request_type,
      }),
      header: {
        title: "Request Type",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "requested_on",
      width: 150,
      type: "Default",
      tooltip: true,
      disableSort: true,
      valueSelector: rowData => ({
        value: Format.date(rowData.requested_on),
      }),
      header: {
        title: "Requested On",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "status",
      width: 200,
      type: "Tag",
      tooltip: true,
      disableSort: true,
      valueSelector: rowData => ({
        value:
          rowData.status === "Complete"
            ? "Mapping Complete"
            : "Mapping In Progress",
      }),
      className: rowData =>
        rowData.status === "Complete"
          ? "application-badge-success"
          : "application-badge-secondary",

      header: {
        title: "Status",
        onClick: onClickHeader,
      },
    },
    {
      dataKey: "file_url",
      width: 200,
      type: "Default",
      disableSort: true,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="w-24">
            <div
              onClick={() => {
                window.location.href = rowData.file_url;
              }}
              className="btn-primary ml-4"
              filename="University Uploaded File"
            >
              Download
            </div>
          </div>
        );
      },
      header: {
        title: "University Uploaded File",
        onClick: onClickHeader,
      },
    },
  ];
};

const ConnectionsConfigurationRequestsScreen = props => {
  const [sortColumn, setSortColumn] = useState("university_name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [prevParams, setPrevParams] = useState({});
  const [searchText, setSearchText] = useState("");
  const isInitialMount = useRef({
    search: true,
    sorting: true,
  });

  useEffect(() => {
    load();
  }, []);

  const load = loadMore => {
    const params = {
      search_text: searchText,

      sort_params: [
        {
          sort_by: sortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    if (loadMore === true || !isEqual(params, prevParams)) {
      if (!loadMore) setPrevParams(params);

      return new Promise((resolve, reject) => {
        props.getConnectionsConfiguration(params, resolve, reject);
      });
    } else {
      return new Promise(function (resolve) {
        resolve();
      });
    }
  };

  const loadMore = () => {
    return load(true);
  };

  const debouncedFetchData = debounce(load, 1000);

  useEffect(() => {
    if (isInitialMount.current["search"]) {
      isInitialMount.current["search"] = false;
    } else {
      debouncedFetchData();
    }

    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchText]);

  useEffect(() => {
    if (isInitialMount.current["sorting"]) {
      isInitialMount.current["sorting"] = false;
    } else {
      load();
    }
  }, [sortColumn, sortDirection]);

  const onClickHeader = ({ dataKey }) => {
    setSortColumn(dataKey);
    setSortDirection(
      sortColumn === dataKey
        ? sortDirection === "desc"
          ? "asc"
          : "desc"
        : "asc",
    );
  };

  const rowClassName = ({ index }) => {
    if (index < 0) {
      return "";
    } else {
      return "blue-bg-onHover app-table-row";
    }
  };

  const {
    connectionsConfiguration,
    matched,
    total,
    isNextPageLoading,
  } = props;
  const hasNextPage = matched > connectionsConfiguration.length;
  const isRowLoaded = ({ index }) =>
    !hasNextPage || index < connectionsConfiguration.length;

  const loadMoreRows = isNextPageLoading ? () => {} : loadMore;

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div className="px-6">
        <Filter
          searchPlaceholder="Search for university name"
          selectedFilters={{}}
          searchText={searchText}
          filterOptions={[]}
          onChangeSearchText={setSearchText}
        />
      </div>

      <div className="h-px bg-gray-300 mb-3" />

      <div className="applicants-list flex my-2 items-center pl-6">
        {total === 0 && isNextPageLoading ? null : (
          <div className="font-bold">
            {matched} configuration requests of {total}
          </div>
        )}
      </div>

      {matched === 0 && !isNextPageLoading ? (
        <NoResultsFound
          title={"Search not found"}
          detail={
            "There are no configuration requests matching the search. Please try again with different keywords or filters"
          }
        />
      ) : (
        <Table
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          totalRowCount={total}
          rows={connectionsConfiguration}
          currentRowCount={connectionsConfiguration.length}
          headerHeight={46}
          rowHeight={62}
          rowClassName={index => {
            return `${rowClassName(index)}`;
          }}
          sortableColumns={sortableColumns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          noRowsRenderer={() => {
            return isNextPageLoading ? (
              <div className="flex flex-col justify-center items-center  h-full w-full">
                <Loader
                  type="Oval"
                  color="grey"
                  height={50}
                  width={50}
                />
              </div>
            ) : null;
          }}
          columns={connectionsConfigurationTableColumns(
            onClickHeader,
          )}
        ></Table>
      )}

      {isNextPageLoading && connectionsConfiguration.length > 0 ? (
        <div
          className={
            "absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"
          }
        >
          <Loader
            type="ThreeDots"
            color="grey"
            height={75}
            width={75}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(
  withRouter(ConnectionsConfigurationRequestsScreen),
);

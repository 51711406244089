import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearPartners: null,

  getPartnersFailure: null,
  getPartnersRequest: ["params", "resolve", "reject"],
  getPartnersSuccess: ["partnersData", "params"],

  getPartnerFailure: null,
  getPartnerRequest: ["partnerId"],
  getPartnerSuccess: ["partner"],

  approvePartnerFailure: null,
  approvePartnerRequest: ["partnerId", "params"],
  approvePartnerSuccess: null,

  declinePartnerFailure: null,
  declinePartnerRequest: ["partnerId", "params"],
  declinePartnerSuccess: null,

  updatePartnerNameFailure: null,
  updatePartnerNameRequest: ["partnerId", "params"],
  updatePartnerNameSuccess: null,

  getPartnerSsoFailure: null,
  getPartnerSsoRequest: ["partnerId"],
  getPartnerSsoSuccess: ["partnerData"],

  getMergePartnersFailure: null,
  getMergePartnersRequest: ["originalId", "duplicateId"],
  getMergePartnersSuccess: ["originalPartner", "duplicatePartner"],

  mergePartnersFailure: null,
  mergePartnersRequest: ["originalId", "duplicateId"],
  mergePartnersSuccess: ["message"],

  swapMergePartners: null,

  getProductOfferingOptionsFailure: null,
  getProductOfferingOptionsRequest: null,
  getProductOfferingOptionsSuccess: ["productOfferingOptions"],

  getPartnersFilterCountriesFailure: null,
  getPartnersFilterCountriesRequest: ["params"],
  getPartnersFilterCountriesSuccess: ["countries"],

  savePartnerFailure: null,
  savePartnerRequest: ["partner"],
  savePartnerSuccess: ["partner"],

  getConnectionsConfigurationFailure: null,
  getConnectionsConfigurationRequest: ["params", "resolve", "reject"],
  getConnectionsConfigurationSuccess: [
    "connectionsConfigurationData",
    "params",
  ],
});

export const PartnersTypes = Types;
export const PartnersCreators = Creators;

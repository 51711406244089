import { API } from "../helpers";
import { SERVER_URL } from "../config";

export const getPartners = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/partners/list`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getNewPartners = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/requested_partners/list`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export function getNewPartnersFiltersCountries(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/requested_partners/countries`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getNewPartnersFilterStatuses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/requested_partners/statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export const getPartner = (authToken, partnerId) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/partners/${partnerId}/edit`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const getNewPartner = (authToken, partnerId) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/requested_partners/${partnerId}`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const savePartner = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/partners`,
    headers: {
      authentication_token: authToken,
    },
    data: data,
  });
};

export const getPartnerFeatures = (authToken, partnerId) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/partners/features?id=${partnerId}`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const updatePartnerFeatures = (
  authToken,
  partnerId,
  partner,
) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/partners/${partnerId}/update_features`,
    headers: {
      authentication_token: authToken,
    },
    data: partner,
  });
};

export const updatePartner = (authToken, partnerId, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/partners/${partnerId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const updateNewPartner = (authToken, partnerId, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/requested_partners/${partnerId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const updatePartnerName = (authToken, partnerId, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/partners/${partnerId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getPartnerSso = (authToken, partnerId) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/partners/${partnerId}/users/sso`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const getMergePartners = (authToken, params) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/partners/merge_partner_info`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
};

export const mergePartners = (authToken, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/admins/partners/merge_partner`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getProductOfferings = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/universities/configurations`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export function getPartnersFilterCountries(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/admins/partners/countries`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export const getConnectionsConfiguration = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/admins/connection_configurations/list`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};
